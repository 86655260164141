#printable {
	display: none;
}

.grey {
	background-color: #f7f7f7;
}
.invoice-container {
	background-color: #fff;
	width: 390px;
	padding: 5px;
}

.center-invoice {
	margin: auto;
}

.invoice {
	border: 1px solid #000;
	width: 370px;
	margin: auto;
	padding: 10px;
}

.margin-top {
	position: absolute;
	left: -10px;
	top: -20px;
	/*top: 70px;*/
}

.invoice > .inovice-header {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.invoice > .inovice-header > .invoice-brand {
}

.stPhone {
	background: #000;
	color: #fff !important;
	font-weight: bold;
}

.invoice > .inovice-header > .header-content p {
	text-align: center;
	margin-top: 5px !important;
	padding: 0px;
	margin: 0px;
	color: #000;
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
}

.invoice > .inovice-header > .invoice-brand > .brand {
	width: 200px;
}

.invoice > .inovice-body {
}

.invoice > .inovice-body .divider {
	border: 1px dashed #000;
	margin-top: 3px;
	margin-bottom: 3px;
}

.invoice > .inovice-body .thead {
}

.invoice > .inovice-body table {
	width: 100%;
}

.invoice > .inovice-body table tbody td {
	padding-top: 5px;
	padding-bottom: 5px;
}

.invoice > .inovice-body table th {
	font-size: 14px;
	font-weight: bold;
	color: #000;
}

.width-35 {
	width: 35px;
	max-width: 35px;
}

.invoice > .inovice-body table tbody p {
	margin: 0px;
	font-size: 14px;
	color: #000;
	font-weight: 500;
}

.text-ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.invoice > .inovice-body table tfoot p {
	margin: 0px;
	font-size: 16px;
	color: #000;
	font-weight: 500;
	line-height: 20px;
}

.price {
	display: flex;
}

.b-text {
	font-weight: bold;
}

.price span {
	font-size: 10px;
	background: #000;
	color: #fff;
	border-radius: 2px;
	padding: 2px;
	font-weight: bold;
}

.invoice > .inovice-body table tfoot strong {
	font-size: 13px;
}

.invoice > .inovice-body .invoice-no {
	display: flex;
}

.invoice > .inovice-body .invoice-no p {
	flex: 1 1;
	font-size: 14px;
	padding: 0px;
	margin: 0px;
	color: #000;
	font-weight: 500;
}

.invoice > .inovice-footer {
}

.address {
	margin-top: 6px !important;
}

.text-14 {
	font-size: 14px !important;
}

.text-justify {
	text-align: justify !important;
}
.text-wrap {
	white-space: normal !important;
}
.text-nowrap {
	white-space: nowrap !important;
}
.text-truncate {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.text-left {
	text-align: left !important;
}
.text-right {
	text-align: right !important;
}
.text-center {
	text-align: center !important;
}
